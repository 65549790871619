import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sessionData: null,
    allSessions: null
};

const sessionSlice = createSlice({
    name: "sessions",
    initialState,
    reducers: {
        updateSessionSlice: (state, action) => {
            state.sessionData = { ...action.payload }
        },
        updateAllSessions: (state, action) => {
            state.allSessions = action.payload
        }
    },
});

export const { updateSessionSlice, updateAllSessions } = sessionSlice.actions;

export default sessionSlice.reducer;