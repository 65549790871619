import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, RouterProvider, Routes, createBrowserRouter } from 'react-router-dom';
import Home from '../Home';
import Login from '../Login';
import { AuthContext } from '../../context/authContext/authContextProvider';


const RouterComp = () => {

    // useEffect(() => {

    // }, [])

    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route exact path='/' element={
                    <ProtectedRoutes redirectTo={"/login"}>
                        <Home />
                    </ProtectedRoutes>
                } />
                <Route exact path='/login' element={
                    <UnProtectedRoute redirectTo={"/"}>
                        <Login />
                    </UnProtectedRoute>
                } />
            </Routes>
        </BrowserRouter>
    )
}

export default RouterComp

export function ProtectedRoutes(props) {
    const { user } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    if (!localStorage.getItem("userAuth")) {
        // Redirect them to the redirect page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return (
            <Navigate to={props.redirectTo} replace />
        );
    }

    return props.children;
}

export function UnProtectedRoute(props) {
    const { user } = useContext(AuthContext)
    if (user) {
        // Redirect them to the redirect page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return (
            <Navigate to={props.redirectTo} replace />
        );
    }

    return props.children;
}

