import React, { useMemo } from 'react'
import styles from "./index.module.scss"
import { useSelector } from 'react-redux'
import { stepsModules } from '../../steps'
import { convertTimestampToSeconds } from '../UserModules'
import moment from 'moment'
import { utils, writeFile } from 'xlsx'

const bestPerformers = [
    {
        name: "Aswin",
        moduleName: "Atonic uterus",
        score: 45,
        timeTaken: "06:12 mins"
    },
    {
        name: "Parul",
        moduleName: "Retained placenta",
        score: 34,
        timeTaken: "06:12 mins"
    },
    {
        name: "Poojya",
        moduleName: "Perineal tear",
        score: 44,
        timeTaken: "06:12 mins"
    },
    {
        name: "Aswin",
        moduleName: "Perineal tear",
        score: 44,
        timeTaken: "06:12 mins"
    },
    {
        name: "Aswin",
        moduleName: "Perineal tear",
        score: 44,
        timeTaken: "06:12 mins"
    },
    {
        name: "Aswin",
        moduleName: "Perineal tear",
        score: 44,
        timeTaken: "06:12 mins"
    },

    {
        name: "Ashfaque",
        moduleName: "Atonic uterus",
        score: 45,
        timeTaken: "06:12 mins"
    },
    {
        name: "Aswin",
        moduleName: "Atonic uterus",
        score: 45,
        timeTaken: "06:12 mins"
    },
    {
        name: "Poojya",
        moduleName: "Perineal tear",
        score: 44,
        timeTaken: "06:12 mins"
    },
    {
        name: "Ashfaque",
        moduleName: "Atonic uterus",
        score: 45,
        timeTaken: "06:12 mins"
    },
    {
        name: "Aswin",
        moduleName: "Atonic uterus",
        score: 45,
        timeTaken: "06:12 mins"
    },
]

const headingObj = [
    // {
    //     label: "Total training conducted",
    //     value: 200,
    //     id: "totalTraining",
    // },
    {
        label: "Total users",
        value: 200,
        id: "totalUsers",
    },
    // {
    //     label: "Total sessions",
    //     value: 200,
    //     id: "totalSessions",
    // },
]


const UserReport = () => {


    const analytics = useSelector(state => state.details.analytics)
    const topPerformers = useSelector(state => state.details.topPerformers)

    const handleDownloadAnalytics = () => {
        if (topPerformers.common && Object.values(topPerformers.common).length > 0) {
            let aoo = []
            Object.values(topPerformers.common).sort((a, b) => b.score - a.score).map(step => {
                let obj = {}
                obj.name = step.name
                obj["Module Name"] = step.testId
                obj.score = step?.score
                obj["Minutes Spent"] = convertTimestampToSeconds((step?.updateTimestamp) - step?.startTimestamp)
                aoo.push(obj)

            })
            var workbook = utils.book_new();

            /* convert table "table1" to worksheet named "Sheet1" */
            var worksheet = utils.json_to_sheet(aoo);
            utils.book_append_sheet(workbook, worksheet, "Sheet1");
            writeFile(workbook, "topPerfomers.xlsx");
        }
    }

    return (
        <div className={styles.UserReportWrapper}>
            <div className={styles.headingObjWrapper}>
                {headingObj.map(item => {
                    return <div className={styles.itemWrapper}>
                        <div className={styles.itemName}>{item.label}</div>
                        <div className={styles.itemValue}>{analytics && analytics[item.id] ? analytics[item.id].count : 0}</div>
                    </div>
                })}
                <div onClick={handleDownloadAnalytics} className={styles.downloadBtn}>
                    Download Analytics
                </div>
            </div>
            <div className={styles.perfomanceWrapperBox}>
                <div className={styles.perfomanceWrapper}>
                    <div className={styles.heading}>Best performers</div>
                    <div style={{ fontWeight: 700 }} className={styles.userWrapper}>
                        <div className={styles.slNo}>Sl. No</div>
                        <div className={styles.userName}>Name</div>
                        <div className={styles.moduleName}>Module completed</div>
                        <div className={styles.score}>Score</div>
                        <div className={styles.timeTaken}>Time taken</div>
                    </div>
                    {topPerformers.common && Object.values(topPerformers.common).length > 0 ?
                        Object.values(topPerformers.common).sort((a, b) => b.score - a.score).map((perfomer, index) => {
                            // console.log(perfomer, "performer")
                            return <div className={styles.userWrapper}>
                                <div className={styles.slNo}>{index + 1}</div>
                                <div className={styles.userName}>{perfomer?.name}</div>
                                {/* {stepsModules[perfomer.id].name} */}
                                <div className={styles.moduleName}>{perfomer.testId}</div>
                                <div className={styles.score}>{perfomer.score}</div>
                                <div className={styles.timeTaken}>{convertTimestampToSeconds((perfomer?.endTimestamp) - perfomer?.startTimestamp)}</div>
                            </div>
                        }) : <div style={{ textAlign: "center", fontWeight: 600, marginTop: "2rem" }}>No Data</div>}
                </div>
            </div>
        </div>
    )
}

export default UserReport