import { getDatabase, ref, child, get, onValue } from "firebase/database";

let unsubscribe = null

export async function getPractiseReport(applicationId, userId, cb = () => { }) {
    try {
        const dbRef = ref(getDatabase(), `${applicationId}/practice_session/${userId}`)
        const snapshot = await get(dbRef)
        if (snapshot.exists()) {
            cb(snapshot.val());
        } else {
            alert("No Data forund.")
            cb(false)
        }
    } catch (error) {
        console.error(error)
        cb(false);
    }
}

export function getReports(applicationId, cb) {
    try {
        const dbRef = ref(getDatabase(), `analytics/${applicationId}/`);
        unsubscribe = onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                cb(snapshot.val());
            } else {
                cb([])
                console.log("No data available");
            }
        })

    } catch (error) {
        console.error(error)
        cb(false);
    }
}

let topUsersListener = null

export function getTopUsers(applicationId, cb) {
    try {
        const dbRef = ref(getDatabase(), `leaderboard/${applicationId}/`);
        topUsersListener = onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                cb(snapshot.val());
            } else {
                cb([])
                console.log("No data available");
            }
        })

    } catch (error) {
        console.error(error)
        cb(false);
    }
}


export const detachReportsListener = {
    unsubscribe,
    topUsersListener
}