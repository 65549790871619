import { collection, doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase";

let unsubscribe = null

export function getUsers(applicationId, cb) {
    try {
        const q = query(collection(db, "users"), where("applicationId", "array-contains", applicationId));
        unsubscribe = onSnapshot(q, (querySnapshot) => {
            const users = [];
            querySnapshot.forEach((doc) => {
                users.push({ ...doc.data(), timestamp: doc["_document"].createTime.timestamp });
            });
            console.log(users.length);
            cb([...users].sort((a, b) => b.timestamp.seconds - a.timestamp.seconds))
        });
    } catch (error) {
        console.error(error)
        // cb(error)
    }
}


export const detachListener = {
    unsubscribe
}