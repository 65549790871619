import React, { createContext, useState, useEffect } from "react";
import { auth } from "../../firebase";
import { updateProfile } from "firebase/auth";


export const AuthContext = createContext();

export default function AuthContextProvider(props) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {

                // updateProfile(auth.currentUser, {
                //     displayName: "Admin"
                // }).then(() => {
                //     console.log(" Profile updated!")
                //     // ...
                // }).catch((error) => {
                //     console.log(error)
                //     // An error occurred
                //     // ...
                // });

                let claims = (await user.getIdTokenResult(true)).claims
                // console.log(claims);
                let __user = {
                    displayName: user.displayName,
                    email: user.email,
                    role: claims.userType,
                    uid: user.email,
                    userid: user.email,
                    f_uid: user.uid,
                };
                console.log(user.displayName)


                localStorage.setItem("userAuth", JSON.stringify(__user));

                setUser({ ...__user });
            } else {
                console.log("user not logged in");
                localStorage.removeItem("userAuth");
                localStorage.clear();
                setUser(null);
            }
        });
    }, []);

    return (
        <AuthContext.Provider value={{ user }}>
            {props.children}
        </AuthContext.Provider>
    );
}
