import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    usersList: null,
    usersHashtable: {}
};

const userSlice = createSlice({
    name: "usersList",
    initialState,
    reducers: {
        updateUsersList: (state, action) => {
            let newUserList = action.payload
            let _userHashTable={}
            state.usersList = action.payload
            newUserList.forEach(user => {
                if(user.uid){
                    _userHashTable[user.uid] = user
                }
            })
            state.usersHashtable = {
                ...state.usersHashtable,
                ..._userHashTable
            }
        },
        updateUserPhone: (state, action) => {
            /**
             * @type {Array}
             */
            let newUserList = state.usersList.filter(user => user.uid !== action.payload.user.uid)
            newUserList.push(action.payload.user)
            state.usersList = [...newUserList]
        },
        addUser: (state, action) => {
            state.usersList.push(action.payload.user)
        }
    },
});

export const { updateUsersList, updateUserPhone, addUser } = userSlice.actions;

export default userSlice.reducer;