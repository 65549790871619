import './App.css';
import Routes from './components/Routes';
import { Provider } from 'react-redux'
import store from './store';
import AuthContextProvider from './context/authContext/authContextProvider';
import RouterComp from './components/Routes';

//FOR CALENDER DATE PICKER
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function App() {
  return (
    <Provider store={store}>
      <AuthContextProvider>
        <RouterComp />
      </AuthContextProvider>
    </Provider>
  );
}

export default App;
