import React, { useReducer, useState } from 'react'
import styles from "./index.module.scss"
import { auth, functions } from '../../firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate, useNavigation } from 'react-router-dom'
import logo from "../../assets/images/rajasthanlogo.png"
import punjabLogo from "../../assets/images/punjabLogo.png"
import { useDispatch, useSelector } from 'react-redux'
import { applicationIdType } from '../../constants/applicationConstants'
import { updateUserType } from '../../features/details/detailSlice'
import { updateAllSessions } from '../../features/session/sessionSlice'
import { getAllSessions } from '../../managers/SessionManager'
import mailImg from "../../assets/images/email.png"
import { httpsCallable } from 'firebase/functions'

const inputs = [
    {
        id: "id",
        label: "Phone Number",
        placeholder: "Enter your Phone Number",
        type: "text"
    },
    {
        id: "code",
        label: "Passcode",
        placeholder: "Enter your Passcode",
        type: "text"
    },
]

const forgotInputs = [
    {
        id: "name",
        label: "Name",
        placeholder: "Enter your Name",
        type: "text"
    },
    {
        id: "email",
        label: "Email",
        placeholder: "Enter your Email",
        type: "text"
    },
]


const defaultErrors = {
    id: "",
    code: ""
}

const actionTypes = {
    handleInputChange: "handleInputChange",
    handleError: "handleError",
    handleLoading: "handleLoading"
}

const frogotReducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case actionTypes.handleInputChange:
        case actionTypes.handleError:
        case actionTypes.handleLoading:
            return { ...state, ...payload }
        default:
            return { ...state }
    }
}




const reducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case actionTypes.handleInputChange:
        case actionTypes.handleError:
        case actionTypes.handleLoading:
            return { ...state, ...payload }
        default:
            return { ...state }
    }
}


const defaultForgotErrors = {
    name: "",
    email: ""
}


const initialState = {
    id: "",
    code: "",
    isLoading: false,
    errors: {
        ...defaultErrors
    }
}

const initialForgotState = {
    name: "",
    email: "",
    isLoading: false,
    errors: {
        ...defaultForgotErrors
    }
}

const Login = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [forgotState, forgotDispatch] = useReducer(frogotReducer, initialForgotState)
    const [errorMessage, showErrorMessage] = useState(false)
    const navigate = useNavigate()
    const [showForgot, setShowForgot] = useState(false)
    const [showForgotValue, setShowForgotValue] = useState(false)
    const reduxDispatch = useDispatch()
    const applicationId = useSelector(state => state.details.applicationId)
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const validateForm = () => {
        let errors = state.errors;

        errors.id =
            state.id.trim().length > 0 ? "" : "Please enter valid Phone Number";
        errors.code =
            state.code.trim().length > 0 ? "" : "Please enter valid Access code";

        dispatch({
            type: actionTypes.handleError,
            payload: { errors: { ...errors } },
        });
    };
    const validateForgotForm = () => {
        let errors = forgotState.errors;
        errors.name =
            forgotState.name.trim().length > 0 ? "" : "Please enter valid name";
        errors.email =
            forgotState.email.trim().length > 0 && validateEmail(forgotState.email) ? "" : "Please enter valid email";
        forgotDispatch({
            type: actionTypes.handleError,
            payload: { errors: { ...errors } },
        });
    };

    const handleForgotPasscode = () => {
        if (forgotState.isLoading) {
            return
        }
        if (showForgotValue) {
            setShowForgotValue(false)
        }
        setShowForgot(prev => !prev)
    }

    const isValidForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
        return valid;
    };

    const handleForgotSubmit = () => {
        forgotDispatch({
            type: actionTypes.handleLoading,
            payload: { isLoading: true },
        });
        validateForgotForm()
        if (!isValidForm(forgotState.errors)) {
            forgotDispatch({
                type: actionTypes.handleLoading,
                payload: { isLoading: false },
            });
            return;
        }
        let obj = {
            email: forgotState.email,
            applicationId
        }
        console.log(obj)
        let cloudRef = httpsCallable(functions,
            "forgotPassword"
        )

        cloudRef(obj).then(res => {
            if (res) {
                forgotDispatch({
                    type: actionTypes.handleLoading,
                    payload: { isLoading: false },
                });
                setShowForgotValue(true)
            } else {
                forgotDispatch({
                    type: actionTypes.handleLoading,
                    payload: { isLoading: false },
                });
            }
        }).catch(err => {
            forgotDispatch({
                type: actionTypes.handleLoading,
                payload: { isLoading: false },
            });
            console.log(err, "err")
        })

    }

    const handleSubmit = () => {
        showErrorMessage(false)
        dispatch({
            type: actionTypes.handleLoading,
            payload: { isLoading: true },
        });
        validateForm()
        if (!isValidForm(state.errors)) {
            dispatch({
                type: actionTypes.handleLoading,
                payload: { isLoading: false },
            });
            return;
        }
        signInWithEmailAndPassword(auth, `+91${state.id}@unfpa.com`, `${state.code}${state.code}`).then(async (userCredential) => {
            // Signed in
            var user = userCredential.user;
            let claims = (await userCredential.user.getIdTokenResult(true)).claims
            let userType = claims.userType ? claims.userType : "user"
            if (user.uid == "RFnrfas9bDMNS6GiA1gQXyvClTJ3") {
                userType = "superAdmin"
            }
            localStorage.setItem("userType", userType);

            navigate("/")
            // ...
        })
            .catch((error) => {
                dispatch({
                    type: actionTypes.handleLoading,
                    payload: { isLoading: false },
                });
                showErrorMessage(true)
                var errorCode = error.code;
                var errorMessage = error.message;
            });
    }

    const handleForgotInputChange = (e) => {
        forgotDispatch({
            type: actionTypes.handleInputChange,
            payload: { [e.target.name]: e.target.value }
        })
    }

    const handleInputChange = (e) => {
        dispatch({
            type: actionTypes.handleInputChange,
            payload: { [e.target.name]: e.target.value }
        })
    }

    return (
        <div className={styles.LoginWrapper}>
            <div className={styles.sidebar}>
                <div className={styles.title}>Welcome to our <br />
                    AMTSL Dashboard</div>
            </div>
            {showForgot && <div className={styles.modalWrapper}>
                <div onClick={handleForgotPasscode} className={styles.modalOverlay}></div>
                <div className={styles.modal}>
                    {showForgotValue ? <>
                        <img src={mailImg} className={styles.mailImg} />
                        <div>Please check mail for your login details</div>
                        <div onClick={handleForgotPasscode} className={styles.continue}>Continue</div>
                    </> : <>{forgotInputs.map((input, i) => {
                        return <div className={styles.inputWrapper}>
                            <label className={styles.label}>{input.label}</label>
                            {<div style={{ position: "relative", width: "100%" }}>{input.id == "id" && <div className={styles.countryCode}>+91</div>}<input name={input.id} style={{ paddingLeft: input.id == "id" ? "3rem" : "2rem" }} className={styles.input} type={input.type} placeholder={input.placeholder} value={forgotState[input.id]} onChange={handleForgotInputChange} /></div>}
                            <div className={styles.errors}>{forgotState.errors[input.id]}</div>
                        </div>
                    })}
                        <button onClick={handleForgotSubmit} disabled={forgotState.isLoading} className={styles.submitBtn}>{!forgotState.isLoading ? "Submit" : <span className={styles.loader}></span>}</button></>}
                </div>
            </div>}
            <div className={styles.mainWrapper}>
                <img src={applicationId == applicationIdType.rajasthan ? logo : punjabLogo} className={styles.logo} />
                <div className={styles.formWrapper}>
                    <div className={styles.title}>Login</div>
                    {inputs.map((input, i) => {
                        return <div key={`${input}-${i}`} className={styles.inputWrapper}>
                            <label className={styles.label}>{input.label}</label>
                            {<div style={{ position: "relative", width: "70%" }}>{input.id == "id" && <div className={styles.countryCode}>+91</div>}<input name={input.id} style={{ paddingLeft: input.id == "id" ? "3rem" : "2rem" }} className={styles.input} type={input.type} placeholder={input.placeholder} value={state[input.id]} onChange={handleInputChange} /></div>}
                            <div className={styles.errors}>{state.errors[input.id]}</div>
                        </div>
                    })}
                    {/* <div className={styles.bottomLink}>Dont have access code? <span className={styles.clickHere}>Click here</span></div> */}
                    <div className={styles.btnWrapper}><button onClick={handleSubmit} disabled={state.isLoading} className={styles.loginBtn}>{!state.isLoading ? "Log In" : <span className={styles.loader}></span>}</button>
                        <div className={styles.forgotPass} onClick={handleForgotPasscode}>Forgot Passcode?</div>
                    </div>
                    {errorMessage && <div className={styles.errors}>Please Enter Valid Credentials</div>}
                </div>
                <div></div>

            </div>
        </div>
    )
}

export default Login