import { getDatabase, ref, child, get, onValue } from "firebase/database";


export function getUserSessions(applicationId, userId) {
    return new Promise(async (response, reject) => {
        try {
            const dbRef = ref(getDatabase());
            get(child(dbRef, `${applicationId}/sessions/${userId}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    response(snapshot.val());
                } else {
                    response([])
                    console.log("No data available");
                }
            }).catch((error) => {
                reject(error);
            });

        } catch (error) {
            console.error(error)
            reject(error)
        }
    })
}


let unsubscribe = null

/**
 * @typedef {Object} Task
 * @property {boolean} completed - Indicates if the task is completed.
 * @property {boolean} inorder - Indicates if the task was completed in order.
 * @property {number} inorderIndex - The index indicating the order of completion; -1 if not completed in order.
 * @property {number} score - The score achieved for this task.
 * @property {string} taskid - The unique identifier of the task.
 */

/**
 * @typedef {Object} Step
 * @property {string} id - The unique identifier of the step.
 * @property {Task[]} task - An array of tasks associated with the step.
 * @property {number} timespent - The time spent on the step in seconds.
 * @property {number} totalPoints - The total score achieved for this step.
 */

/**
 * @typedef {Object} TestSession
 * @property {boolean} completed - Indicates if the test session is completed.
 * @property {number} endTimestamp - The timestamp (in milliseconds) when the test session ended.
 * @property {number} score - The total score of the test session.
 * @property {string} sessionId - The unique identifier for the test session.
 * @property {Step[]} setps - An array of steps involved in the test session.
 * @property {number} startTimestamp - The timestamp (in milliseconds) when the test session started.
 * @property {string} testId - The unique identifier for the test.
 * @property {number} updateTimestamp - The timestamp (in milliseconds) when the test session was last updated.
 */

/**
 * @typedef {Object.<string, TestSession>} TestSessionsMap
 * - A map of testSessionId to TestSession objects.
 */

/**
 * @typedef {Object.<string, TestSessionsMap>} UserTestsMap
 * - A map of userId to TestSessionsMap, representing all test sessions for each user.
 */

export function getAllSessions(applicationId, cb) {
    try {
        const dbRef = ref(getDatabase(), `${applicationId}/sessions/`);
        unsubscribe = onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                cb(snapshot.val());
            } else {
                cb([])
                console.log("No data available");
            }
        })

    } catch (error) {
        console.error(error)
        cb(false);
    }
}

export const detachSessionsListener = {
    unsubscribe
}