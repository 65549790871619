import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";
import Fuse from "fuse.js";
import nextArrow from "../../assets/images/nextArrow.png";
import editBtn from "../../assets/images/edit.png";
import deleteBtn from "../../assets/images/deleteBtn.png";
import UserForm from "../UserForm";
import { useDispatch, useSelector } from "react-redux";
import { applicationIdType } from "../../constants/applicationConstants";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import { updateUserPhone } from "../../features/users/userSlice";
import { convertTimestampToSeconds, sumOFHoursWorked } from "../UserModules";
import { DateRangePicker } from 'react-date-range';
import { formatSeconds } from "../../util";
import { utils, writeFile } from "xlsx";
import { da } from "date-fns/locale";

const searchOptions = {
    includeScore: true,
    keys: ["first_name"],
};

const Allusers = ({ searchInput, handleShowUser }) => {
    const [showUserForm, setShowUserForm] = useState(false);
    const [dataVisible, setDataVisible] = useState([]);
    /**
     * @type {Array}
     */
    const usersList = useSelector((state) => state.users.usersList);
    const usersHashtable = useSelector((state) => state.users.usersHashtable)

    const allSessions = useSelector(state => state.sessions.allSessions)
    const [loading, setLoading] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const [editable, setEditable] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const applicationId = useSelector(state => state.details.applicationId)
    const dispatch = useDispatch()
    const fuseRef = useRef(null);
    const [insitiueFilter, setInsititueFilter] = useState({
        "SMTI": true,
        "NMTI": true
    })

    useEffect(() => {
        if (usersList && usersList.length) {
            fuseRef.current = new Fuse(usersList, searchOptions);
            setDataVisible(usersList);
        }
    }, [usersList]);

    useEffect(() => {
        if (searchInput && searchInput.length > 0 && fuseRef.current) {
            const result = fuseRef.current.search(`${searchInput}`);

            setDataVisible(result.map((rs) => rs.item));
        } else {
            setDataVisible(usersList);
        }
    }, [searchInput]);



    const handleUserForm = () => {
        setShowUserForm((prev) => !prev);
    };

    const handlePhoneUpdate = (e) => {
        if (e.target.value.length < 3) {
            return
        }
        setPhoneNumber(e.target.value)
    }

    const handleUpdatePhoneNumber = (_user) => {
        let user = usersHashtable[_user.uid]
        if (phoneNumber.length !== 13) {
            return
        }
        setLoading(true)
        let obj = {
            phoneNumber,
            userId: user.uid
        }
        let cloudRef = httpsCallable(functions,
            "updateUserPassword"
        )
        cloudRef(obj).then(res => {
            if (res) {
                let _user = { ...user }
                _user.phoneNumber = phoneNumber
                dispatch(updateUserPhone({ user: _user }))
                handleEditable(null, null)
                setLoading(false)
            } else {
                setLoading(false)
                console.log("error")
            }
        })
    }

    const getTotalTimeTaken = (user) => {
        let time1 = null;
        let time2 = null
        let totalTime = null
        if (allSessions && allSessions[user.uid]) {
            Object.values(allSessions[user.uid]).map(_session => {
                if (_session.endTimestamp) {
                    if (time1 == null) {
                        time1 = convertTimestampToSeconds(_session?.endTimestamp - _session?.startTimestamp)
                    } else {
                        time2 = convertTimestampToSeconds(_session?.endTimestamp - _session?.startTimestamp)
                    }
                    if (time1 && time2) {
                        totalTime = sumOFHoursWorked(time1, time2)
                        time1 = totalTime
                        time2 = null
                    }
                }
            })
        }

        if (totalTime == null) {
            if (time1) {
                return time1
            } else {
                return "00:00"
            }
        } else {
            return totalTime
        }

    }

    const handleEditable = (user, ind) => {
        setEditable(ind)
        setPhoneNumber(user?.phoneNumber)
    }

    const handleDelete = (user) => {
        if (deleting || !user) {
            return;
        }
        let confirmation = window.confirm("Do you really want to Delete?");
        if (confirmation) {
            setDeleting(true)
            let deleteRef = httpsCallable(functions,
                "deleteUser"
            )
            deleteRef({ userId: user.uid }).then(() => {
                setDeleting(false)
            }).catch((err) => {
                console.log("error in deleting user", err)
                setDeleting(false)
            })
        }
    }

    const handleSelect = (ranges) => {
        // console.log('handleSelect');
        // console.log(ranges);
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
        setSelectionRange(ranges.selection)
    }

    const [selectionRange, setSelectionRange] = useState({
        // startDate: new Date(),
        // endDate: new Date(),
        key: 'selection',
    });

    const currentData = useMemo(() => {
        // console.log(selectionRange);
        // console.log(allSessions);
        if (!allSessions) {
            return []
        }
        /**
         * @type {import("../../managers/SessionManager").UserTestsMap}
         */
        const sessionsData = allSessions
        const finalObject = []

        let _usersHashtable = structuredClone(usersHashtable)

        //Iterate over ALl Users Sessions
        let userIds = Object.keys(sessionsData);
        userIds.forEach((userId, index) => {
            // if (index != 0) {
            //     console.log(usersHashtable[userId]);
            //     // return
            // }
            if (!_usersHashtable[userId]) {
                //not in userList for this app
                return
            }
            if (!insitiueFilter[_usersHashtable[userId].institute]) {
                return
            }
            let userSessionsData = sessionsData[userId]
            let userSessionSummary = {
                name: _usersHashtable[userId].first_name + " " + _usersHashtable[userId].last_name,
                code: _usersHashtable[userId].code,
                phoneNumber: _usersHashtable[userId].phoneNumber,
                uid: userId,
                testTaken: 0,
                totalScore: 0,
                timespent: 0,
                state: _usersHashtable[userId].state,
                institute: _usersHashtable[userId].institute,
                designation: _usersHashtable[userId].designation,
                experience: _usersHashtable[userId].experience
            }

            //Iterate over sessions
            let sessionIds = Object.keys(userSessionsData)
            sessionIds.forEach(sessionId => {
                let sessionData = userSessionsData[sessionId]
                let isValidRecord = false
                // In Sessoins, check which all are in given range
                if (!selectionRange.startDate && !selectionRange.endDate) {
                    isValidRecord = true
                } else if (new Date(selectionRange.startDate).getTime() == new Date(selectionRange.endDate).getTime()) {
                    isValidRecord = (sessionData.startTimestamp > new Date(selectionRange.startDate).getTime()) && (sessionData.startTimestamp < (new Date(selectionRange.startDate).getTime() + 24 * 60 * 60 * 1000))
                }
                else {
                    //show in range only
                    isValidRecord = (sessionData.startTimestamp > new Date(selectionRange.startDate).getTime()) && (sessionData.startTimestamp < (new Date(selectionRange.endDate).getTime() + 24 * 60 * 60 * 1000))
                }

                if (isValidRecord) {
                    userSessionSummary.testTaken += 1

                    // also get the latest startTimestamp
                    if (!userSessionSummary.lastTestStartTimestamp) {
                        userSessionSummary.lastTestStartTimestamp = sessionData.startTimestamp
                    } else {
                        userSessionSummary.lastTestStartTimestamp = sessionData.startTimestamp > userSessionSummary.lastTestStartTimestamp ? sessionData.startTimestamp : userSessionSummary.lastTestStartTimestamp;
                    }

                    // sum up the time and score
                    if (sessionData.score)
                        userSessionSummary.totalScore += sessionData.score

                    if (sessionData.endTimestamp) {
                        userSessionSummary.timespent += (sessionData.endTimestamp - sessionData.startTimestamp) / 1000
                    } else if (sessionData.updateTimestamp) {
                        userSessionSummary.timespent += (sessionData.updateTimestamp - sessionData.startTimestamp) / 1000
                    }
                }
            })
            // console.log(userSessionSummary);
            if (userSessionSummary.testTaken != 0)
                finalObject.push(userSessionSummary)

            delete _usersHashtable[userId]
        })

        // order according to starttimestamp
        finalObject.sort((x, y) => y.lastTestStartTimestamp - x.lastTestStartTimestamp)

        if (!selectionRange.startDate && !selectionRange.endDate) {
            //add users which have not done any excerise till now
            Object.keys(_usersHashtable).forEach(userId => {
                if (!insitiueFilter[_usersHashtable[userId].institute]) {
                    return
                }
                finalObject.push({
                    name: _usersHashtable[userId].first_name + " " + _usersHashtable[userId].last_name,
                    code: _usersHashtable[userId].code,
                    phoneNumber: _usersHashtable[userId].phoneNumber,
                    uid: userId,
                    testTaken: 0,
                    totalScore: 0,
                    timespent: 0,
                    state: _usersHashtable[userId].state,
                    institute: _usersHashtable[userId].institute,
                    designation: _usersHashtable[userId].designation,
                    experience: _usersHashtable[userId].experience,
                    lastTestStartTimestamp: ""
                })
            })
        }

        return finalObject
    }, [selectionRange, usersHashtable, insitiueFilter, allSessions])

    const totalTraniningCount = useMemo(() => {
        let count = 0;
        currentData.forEach(data => {
            count += data.testTaken
        })
        return count;
    }, [currentData])

    const userRegisterCount = useMemo(() => {
        if (!usersList) {
            return 0
        }
        if (!selectionRange.startDate && !selectionRange.endDate) {
            return usersList.length
        }
        else {
            //show in range only
            let count = 0;
            let startTime = new Date(selectionRange.startDate).getTime()
            let endTime = new Date(selectionRange.endDate).getTime()
            if (startTime == endTime) {
                endTime = (new Date(selectionRange.startDate).getTime() + 24 * 60 * 60 * 1000)
            } else {
                endTime += 24 * 60 * 60 * 1000
            }
            usersList.forEach(user => {
                if (!user.timestamp) {
                    return
                }
                let registerTimestamp = user.timestamp.seconds * 1000
                let isValidRecord = (registerTimestamp > startTime) && (registerTimestamp < endTime)
                count = isValidRecord ? count + 1 : count;
            })
            return count;
        }

    }, [selectionRange, usersList])

    const handleDownload = () => {

        var workbook = utils.book_new();
        /* convert table "table1" to worksheet named "Sheet1" */
        var worksheet = utils.json_to_sheet(currentData.map(data => ({
            ...data,
            lastTestStartTimestamp: data.lastTestStartTimestamp ? new Date(data.lastTestStartTimestamp).toLocaleString() : "",
            timespent: formatSeconds(Math.floor(data.timespent))
        })));
        utils.book_append_sheet(workbook, worksheet, "Sheet1");
        let fileName = selectionRange.startDate ? `userDetails_from${new Date(selectionRange.startDate).toLocaleDateString()} to ${new Date(selectionRange.endDate).toLocaleDateString()}.xlsx` : 'UserDetails.xlsx';
        writeFile(workbook, fileName);
    }


    const HandleCheckboxUpdate = (e) => {
        // console.log(e.target.name, e.target.checked);
        setInsititueFilter((prev) => ({
            ...prev,
            [e.target.name]: e.target.checked
        }))
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.allusersWrapper}>
                <div className={styles.header}>
                    Report
                    {/* Latest Report */}
                    <div className={styles.headerRightSide}>
                        <div className={styles.btnWrapper}>
                            <div onClick={handleUserForm} className={styles.addUser}>
                                + Add User
                            </div>
                        </div>

                        <div className={styles.btnWrapper}>
                            <div onClick={handleDownload} className={styles.addUser}>
                                Download
                            </div>
                        </div>

                        <div className={styles.btnWrapper}>
                            <div onClick={() => {
                                setSelectionRange({
                                    key: 'selection',
                                })
                            }} className={styles.addUser}>
                                Reset Date Filter
                            </div>
                        </div>
                    </div>

                </div>
                <div className={styles.usersWrappers}>
                    <div className={styles.lineWrapperHeading}>
                        <div>Name</div>
                        <div>Passcode</div>
                        <div>Mobile Number</div>
                        <div>Total Timespent (hh:mm:ss)</div>
                        <div>Assessment Taken</div>
                        <div>Last Assessment</div>
                    </div>
                    {
                        currentData.length > 0 ?
                            <>
                                {
                                    currentData.map((user, index) => (
                                        <div key={index} style={{ position: "relative" }}>
                                            <div className={styles.editBtn}>
                                                {
                                                    editable == index && phoneNumber ?
                                                        <div onClick={() => handleUpdatePhoneNumber(usersHashtable[user.uid])} className={styles.doneBtn}>
                                                            {loading ? <span className={styles.loader}></span> : "done"}
                                                        </div>
                                                        :
                                                        <img onClick={() => handleEditable(usersHashtable[user.uid], index)} src={editBtn} />
                                                }
                                                <img className={styles.deleteBtn} onClick={() => handleDelete(usersHashtable[user.uid])} src={deleteBtn} />
                                            </div>
                                            <div style={{ background: editable == index && phoneNumber ? "#fff" : "none" }} className={`${styles.lineWrapper} ${user.testTaken == 0 ? styles.disabled : ''}`}>
                                                <div onClick={() => handleShowUser(usersHashtable[user.uid])} >{user.name}</div>
                                                <div>{user.code}</div>
                                                {editable == index && phoneNumber ? <input value={phoneNumber} type="text" onChange={handlePhoneUpdate} className={styles.phoneInput} /> : <div>{user.phoneNumber}</div>}
                                                <div>{formatSeconds(Math.floor(user.timespent))}</div>
                                                <div>{user.testTaken}</div>
                                                <div>{user.lastTestStartTimestamp ? new Date(user.lastTestStartTimestamp).toLocaleString() : ""}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                            :
                            <div className={styles.noUsers}>No Users</div>
                    }
                    {/* {
                        dataVisible && dataVisible.filter(_user => _user.applicationId.includes(applicationId)).length > 0 ?
                            dataVisible.filter(_user => _user.applicationId.includes(applicationId)).map((user, index) => {
                                return (
                                    <div key={index} style={{ position: "relative" }}><div className={styles.editBtn}>
                                        {
                                            editable == index && phoneNumber ?
                                                <div onClick={() => handleUpdatePhoneNumber(user)} className={styles.doneBtn}>
                                                    {loading ? <span className={styles.loader}></span> : "done"}
                                                </div>
                                                :
                                                <img onClick={() => handleEditable(user, index)} src={editBtn} />
                                        }
                                        <img className={styles.deleteBtn} onClick={() => handleDelete(user)} src={deleteBtn} />
                                    </div>
                                        <div style={{ background: editable == index && phoneNumber ? "#fff" : "none" }} className={styles.lineWrapper}>
                                            <div onClick={() => handleShowUser(user)} >{user.first_name}</div>
                                            <div>{user.code}</div>
                                            {editable == index && phoneNumber ? <input value={phoneNumber} type="text" onChange={handlePhoneUpdate} className={styles.phoneInput} /> : <div>{user.phoneNumber}</div>}
                                            <div>{getTotalTimeTaken(user)}</div>
                                        </div>
                                    </div>
                                );
                            }) :
                            <div className={styles.noUsers}>No Users</div>
                    } */}
                </div>
                {showUserForm && (
                    <div className={styles.modalWrapper}>
                        <div onClick={handleUserForm} className={styles.modalOverlay}></div>
                        <div className={styles.modal}>
                            <UserForm handleUserForm={handleUserForm} />
                        </div>
                    </div>
                )}
            </div>
            <div>
                <DateRangePicker
                    className="datePicker"
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showMonthAndYearPickers={true}
                    showDateDisplay={selectionRange.startDate ? true : false}
                    editableDateInputs={false}
                    startDatePlaceholder={"--"}
                    endDatePlaceholder={"--"}
                // showDateDisplay={false}
                />

                <div className={styles.checkboxContainer}>

                    <div className={styles.checkboxItem}>
                        <input type="checkbox" id="SMTI" name="SMTI" onChange={HandleCheckboxUpdate} defaultChecked />
                        <label htmlFor="SMTI">SMTI</label>
                    </div>

                    <div className={styles.checkboxItem}>
                        <input type="checkbox" id="NMTI" name="NMTI" onChange={HandleCheckboxUpdate} defaultChecked />
                        <label htmlFor="NMTI"  >NMTI</label>
                    </div>

                </div>

                <div className={styles.dataRow}>
                    <div className={styles.dataBlock}>
                        total Tranining Count:
                        <b>
                            {totalTraniningCount}
                        </b>
                    </div>

                    <div className={styles.dataBlock}>
                        User Register Count:
                        <b>
                            {userRegisterCount}
                        </b>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default Allusers;
