import * as d3 from "d3";
import { useRef, useEffect } from "react";
import './index.css';
import style from './index.module.scss';

const COLORS = {
    "NoPPH": "#ff00f5",//"#1E3A8A",
    "PerinealTear": "#9B2226",
    "AtonicUterus": "#004F2D",
    "IncompletePlacenta": "#D97706",
    "Combined": "#4C1D95"
}
const ToggleLine_dots = (id, val) => {
    document.querySelector(`#${id}`).style.opacity = val ? 1 : 0
    document.querySelectorAll(`.${id}_dots`).forEach(el => el.style.opacity = val ? 1 : 0)
}
export default function UserPlot({
    mainData,
    width = 640,
    height = 400,
    marginTop = 20,
    marginRight = 20,
    marginBottom = 20,
    marginLeft = 20
}) {
    const svgRef = useRef(null)
    const createGraph = async () => {
        let dataContainer = {
            "NoPPH": {},
            "PerinealTear": {},
            "AtonicUterus": {},
            "IncompletePlacenta": {},
            "Combined": {}
        }

        Object.keys(mainData).forEach(entryKey => {
            let entry = mainData[entryKey]
            // console.log(entry);
            let dateKey = new Date(entry.startTimestamp).toDateString()

            function AddEntry(__dataContainer, __dateKey) {
                if (!__dataContainer.hasOwnProperty(__dateKey)) {
                    __dataContainer[__dateKey] = []//0
                }
                __dataContainer[__dateKey].push({ date: new Date(entry.startTimestamp).toLocaleString(), score: entry.score, testId: entry.testId })
                // dataContainer[dateKey] += 1//entry.score
            }

            AddEntry(dataContainer["Combined"], dateKey)
            AddEntry(dataContainer[entry.testId], dateKey)
        })

        // set the dimensions and margins of the graph
        var margin = { top: 20, right: 20, bottom: 50, left: 70 },
            width = 960 - margin.left - margin.right,
            height = 500 - margin.top - margin.bottom;

        // append the svg object to the body of the page
        document.querySelector("#plot").innerHTML = ""

        /**
             * @type {SVGElement}
            */
        var svg = d3.select("#plot").append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        // add X axis and Y axis
        var x = d3.scaleTime().range([0, width]);
        var y = d3.scaleLinear().range([height, 0]);

        function DrawLineOnGraph(id, _dataContainer, color = "steelblue", applyAxisTranform = false, showTestId = true) {

            let data = []
            Object.keys(_dataContainer).forEach(dcKey => {
                let totalScore = 0
                _dataContainer[dcKey].forEach(entry => {
                    totalScore += entry.score;//> 0 ? entry.score : 0 
                })
                totalScore = totalScore > 0 ? totalScore : 0;
                data.push({
                    date: new Date(dcKey),
                    value: totalScore,// < 0 ? 0 : dataContainer[dcKey], //changed to total time played
                    odata: _dataContainer[dcKey].sort((x, y) => new Date(x.date).getTime() - new Date(y.date).getTime())
                })
            })

            data.sort((x, y) => new Date(x.date).getTime() - new Date(y.date).getTime())
            // console.log(data);

            if (applyAxisTranform) {
                x.domain(d3.extent(data, (d) => { return d.date; }));
                y.domain([0, d3.max(data, (d) => { return d.value; })]);

                svg.append("g")
                    .attr("transform", `translate(0, ${height})`)
                    .call(d3.axisBottom(x));

                svg.append("g")
                    .call(d3.axisLeft(y));
            }

            // add the Line
            var valueLine = d3.line()
                .x((d) => { return x(d.date); })
                .y((d) => { return y(d.value); });

            // svg
            //     .append('defs')
            //     .append('marker')
            //     .attr('id', 'dot')
            //     .attr('viewBox', [0, 0, 10, 10])
            //     .attr('refX', 5)
            //     .attr('refY', 5)
            //     .attr('markerWidth', 5)
            //     .attr('markerHeight', 5)
            //     .append('circle')
            //     .attr('cx', 5)
            //     .attr('cy', 5)
            //     .attr('r', 5)
            //     .style('fill', '#3752f6');

            svg.append("path")
                .data([data])
                .attr("id", id)
                .attr("class", "line")
                .attr("fill", "none")
                .attr("stroke", color)// "steelblue")
                // .attr('marker-start', 'url(#dot)')
                // .attr('marker-mid', 'url(#dot)')
                // .attr('marker-end', 'url(#dot)')
                .attr("stroke-width", 1.5)
                .attr("d", valueLine);

            var path = svg.selectAll("dot")
                .data(data)
                .enter().append("circle")
                .attr("class", id + "_dots")
                .attr("r", 5)
                .attr("cx", function (d) {
                    return x(d.date);
                })
                .attr("cy", function (d) {
                    return y(d.value);
                })
                .attr("stroke", color)//"steelblue")
                .attr("stroke-width", 1.5)
                .attr("fill", "#FFFFFF")
                .on('mouseover', function (d, i) {
                    console.log(i);
                    d3.select(this).transition()
                        .duration('100')
                        .attr("r", 7);

                    //Makes div appear
                    div.transition()
                        .duration(100)
                        .style("opacity", 1);

                    let info = "<ol style='margin-right: 1em;'>"
                    i.odata.forEach(d => info += `<li> ${d.date} :: ${d.score} ${showTestId ? "[" + d.testId + "]" : ''} </li>`)
                    info += "</ol>"
                    // console.log(info);
                    // div.html(d3.format(".2f")(i.value))
                    div.html(info)
                        .style("left", (d.pageX + 10) + "px")
                        .style("top", (d.pageY - 15) + "px");
                })
                .on('mouseout', function (d, i) {
                    // console.log(d, i);
                    d3.select(this).transition()
                        .duration('200')
                        .attr("r", 5);

                    //makes div disappear
                    div.transition()
                        .duration('200')
                        .style("opacity", 0);
                });;
        }
        // console.log(dataContainer);
        DrawLineOnGraph("Combined", dataContainer["Combined"], COLORS.Combined, true, true)
        DrawLineOnGraph("NoPPH", dataContainer["NoPPH"], COLORS.NoPPH)
        ToggleLine_dots(`NoPPH`, false)

        DrawLineOnGraph("PerinealTear", dataContainer["PerinealTear"], COLORS.PerinealTear)
        ToggleLine_dots(`PerinealTear`, false)

        DrawLineOnGraph("AtonicUterus", dataContainer["AtonicUterus"], COLORS.AtonicUterus)
        ToggleLine_dots(`AtonicUterus`, false)

        DrawLineOnGraph("IncompletePlacenta", dataContainer["IncompletePlacenta"], COLORS.IncompletePlacenta)
        ToggleLine_dots(`IncompletePlacenta`, false)



        var div = d3.select("#plot").append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);

    }

    useEffect(() => {
        createGraph();
    }, []);

    const HandleCheckbox = (e) => {
        ToggleLine_dots(e.target.name, e.target.checked)
    }

    return (
        <div style={{
            position: "relative",
            width: "fit-content"
        }}>
            <div className={style.checkboxContainer}>
                <div>
                    <input type="checkbox" name="Combined" onChange={HandleCheckbox} defaultChecked />
                    <label
                        style={{
                            color: COLORS.Combined
                        }}
                    >Combined </label>
                </div>

                <div>
                    <input type="checkbox" name="NoPPH" onChange={HandleCheckbox} />
                    <label
                        style={{
                            color: COLORS.NoPPH
                        }}
                    >NoPPH </label>
                </div>

                <div>
                    <input type="checkbox" name="IncompletePlacenta" onChange={HandleCheckbox} />
                    <label
                        style={{
                            color: COLORS.IncompletePlacenta
                        }}
                    >IncompletePlacenta </label>
                </div>

                <div>
                    <input type="checkbox" name="PerinealTear" onChange={HandleCheckbox} />
                    <label
                        style={{
                            color: COLORS.PerinealTear
                        }}
                    >PerinealTear </label>
                </div>

                <div>
                    <input type="checkbox" name="AtonicUterus" onChange={HandleCheckbox} />
                    <label
                        style={{
                            color: COLORS.AtonicUterus
                        }}
                    >AtonicUterus </label>
                </div>
            </div>
            <p style={{
                position: "absolute",
                left: "-0.75rem",
                top: "50%",
                fontWeight: "bold",
                transform: "rotate(270deg)translate(50%,0)"
            }}>Total Score</p>
            <p style={{
                position: "absolute",
                bottom: "-0.75rem",
                left: "50%",
                fontWeight: "bold",
                marginTop: "1rem",
                transform: "translate(50%, 0px)"
            }}>Time</p>
            <div id="plot" ref={svgRef}>

            </div>
        </div>
    );
}