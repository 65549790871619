import { configureStore } from "@reduxjs/toolkit";
import detailsReducer from "./features/details/detailSlice";
import sessionReducer from "./features/session/sessionSlice";
import usersReducer from "./features/users/userSlice";


const store = configureStore({
    reducer: {
        details: detailsReducer,
        sessions: sessionReducer,
        users: usersReducer
    },
});

export default store;