import React, { useReducer, useState } from 'react'
import styles from "./index.module.scss"
import { cloudFunction_as, functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import tick from "../../assets/images/tick.png"
import { useDispatch, useSelector } from 'react-redux'
import { addUser } from '../../features/users/userSlice'
import { InstituteNames } from '../../constants/applicationConstants'


const inputs = [
    {
        name: "First Name",
        id: "first_name",
        type: "text",
    },
    {
        name: "Surname",
        id: "last_name",
        type: "text",
    },
    {
        name: "Designation",
        id: "designation",
        type: "select",
        options: [
            {
                name: "MidWife"
            },
            {
                name: "Nurse"
            },
            {
                name: "IME"
            },
            {
                name: "NME"
            },
            {
                name: "Doctor"
            },
        ]
    },
    {
        name: "Mobile number",
        id: "phoneNumber",
        type: "number",
    },
    {
        name: "Email ID",
        id: "email",
        type: "text",
    },
    {
        name: "Education",
        id: "education",
        type: "text",
    },
    {
        name: "Birth Date",
        id: "date_of_birth",
        type: "date",
    },
    {
        name: "Gender",
        id: "gender",
        type: "select",
        options: [
            {
                name: 'Male',
                value: "male"
            },
            {
                name: 'Female',
                value: "female"
            },
        ]
    },
    {
        name: "Institute",
        id: "institute",
        type: "select",
        options: [
            {
                name: InstituteNames.NMTI,
                id: "NMTI"
            },
            {
                name: "SMTI",
                id: "SMTI"
            },
        ]
    },
    {
        name: "Years of clinical experience",
        id: "experience",
        type: "select",
        options: new Array(30).fill(1).map((arr, index) => {
            return {
                name: index
            }
        })

    }
]



const defaultErrors = {
    first_name: "",
    last_name: "",
    designation: "",
    email: "",
    phoneNumber: '',
    education: "",
    date_of_birth: "",
    gender: "",
    institute: "",
    experience: '',
}


const initialState = {
    first_name: "",
    last_name: "",
    designation: "",
    phoneNumber: '',
    email: "",
    education: "",
    date_of_birth: "",
    gender: "",
    institute: "",
    experience: '',
    errors: {
        ...defaultErrors
    }

}


const actionType = {
    handleInputChange: 'handleInputChange',
    handleError: "handleError",
    handleLoading: "handleLoading",
    handleInitialState: "handleInitialState",
}

const reducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case actionType.handleInputChange:
        case actionType.handleError:
        case actionType.handleLoading:
            return { ...state, ...payload }
        case actionType.handleInitialState:
            return { ...JSON.parse(JSON.stringify(initialState)) }
        default:
            return { ...state }
    }
}

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};


const UserForm = ({ handleUserForm }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const applicationId = useSelector(state => state.details.applicationId)
    const reduxDispatch = useDispatch()

    const validateForm = () => {
        let errors = state.errors;
        errors.first_name =
            state.first_name.trim().length > 0 ? "" : "Please enter valid First Name";
        errors.last_name =
            state.last_name.trim().length > 0 ? "" : "Please enter valid Surname";
        errors.email =
            state.email.trim().length > 0 && validateEmail(state.email) ? "" : "Please enter valid Surname";
        errors.designation =
            state.designation.trim().length > 0 ? "" : "Please enter valid Designation";
        errors.phoneNumber =
            state.phoneNumber.trim().length > 0 && state.phoneNumber.trim().length == 10 ? "" : "Please enter valid Phone Number";
        errors.education =
            state.education.trim().length > 0 ? "" : "Please enter valid Education";
        errors.date_of_birth =
            state.date_of_birth.trim().length > 0 ? "" : "Please select your date of Birth"
        errors.gender =
            state.gender.trim().length > 0 ? "" : "Please select your Gender"
        errors.institute =
            state.institute.trim().length > 0 ? "" : "Please select Institute"
        errors.experience =
            state.experience.trim().length > 0 ? "" : "Please select your Experience"


        dispatch({
            type: actionType.handleError,
            payload: { errors: { ...errors } },
        });
    };

    const isValidForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
        return valid;
    };





    const handleInputChange = (e) => {
        dispatch({
            type: actionType.handleInputChange,
            payload: { [e.target.name]: e.target.value }
        })
    }
    const [showResponse, setShowResponse] = useState(false)
    const [accessCode, setaccessCode] = useState(false)
    const [loading, setLoading] = useState(false)


    const handleSubmitData = () => {
        setLoading(true)
        validateForm()
        if (!isValidForm(state.errors)) {
            dispatch({
                type: actionType.handleLoading,
                payload: { isLoading: false },
            });
            setLoading(false)
            return;
        }
        let _state = { ...state }
        delete _state.errors
        _state.phoneNumber = "+91" + _state.phoneNumber
        _state.state = applicationId
        _state.applicationId = [applicationId]
        const obj = {
            userType: "user",
            ..._state
        }

        let cloudRef = httpsCallable(functions,
            "createdashboarduserv2"
        )


        cloudRef(obj).then(res => {
            if (res) {
                setLoading(false)
                // reduxDispatch(addUser({ user: { ...obj, code: res.data.usercode } }))
                setaccessCode(res.data.usercode)
                setShowResponse(true)
            } else {
                setLoading(false)
                setShowResponse(false)
            }
        })
    }

    const handleNextUser = () => {
        setShowResponse(false)
        dispatch({
            type: actionType.handleInitialState,
            payload: {}
        })
    }

    return (
        <><div className={styles.formWrapper}>
            <div className={styles.header}>User registration</div>
            <div className={styles.inputsWrapper}>
                {inputs.map(input => {
                    return <div className={`${styles.inputBox} ${input.type == "textArea" && styles.textArea}`}>
                        <div className={styles.label}>{input.name}
                            <div className={styles.errors}>{state.errors[input.id]}</div>
                        </div>
                        {input.type == "textArea" ? <textarea name={input.id} value={state[input.id]} onChange={handleInputChange} type={input.type} className={styles[input.type]} /> : input.type == "select" ? <select name={input.id} onChange={handleInputChange} className={styles[input.type]}>
                            <option hidden disabled selected value></option>
                            {input.options.map(opt => {
                                return <option value={input.id == "institute" ? opt.id : opt.name} className={styles.options}>{input.id == "institute" ? InstituteNames[opt.id] : opt.name}</option>
                            })}
                        </select> : input.id == "phoneNumber" ? <div className={styles.inputWrapperPhone}><div className={styles.countryCode}>+91</div>
                            <input name={input.id} style={{ width: "100%", paddingLeft: "3.25rem" }} value={state[input.id]} onChange={handleInputChange} type={input.type} className={styles[input.type]} />
                        </div> : <input name={input.id} value={state[input.id]} onChange={handleInputChange} type={input.type} className={styles[input.type]} />
                        }
                    </div>
                })}
            </div>
            <div onClick={handleSubmitData} disabled={loading} className={styles.submit}>{loading ? <span className={styles.loader}></span> : "Submit"}</div>
        </div >
            {showResponse && <div className={styles.modalWrapperInset}>
                <div className={styles.modal}>
                    <img src={tick} className={styles.img} />
                    <div>User registration successful </div>
                    <div>{`User passcode is : ${accessCode}`}</div>
                    <div className={styles.btnWrapper}>
                        <button onClick={handleUserForm} style={{ backgroundColor: "#505050" }}>Back</button>
                        <button onClick={handleNextUser}>Add Another</button>
                    </div>
                </div>
            </div>
            }</>
    )
}

export default UserForm